window.menuIsOpen = false;

const burger = document.querySelector("#burger");
const burgerIcon = burger.querySelector(".page-header__burger-img");
const districtHeader = document.querySelector(".page-header__districts");
const feedbackHeader = document.querySelector(".page-header__button");

window.closeMenu = (needClose = true) => {
  setMenuAttr();
  if (needClose) {
    window.menuIsOpen = false;
  }
  //
};

function setMenuAttr(img = "img/burger.svg", display = "") {
  burgerIcon.src = img;
  districtHeader.style.display = display;
  feedbackHeader.style.display = display;
}

burger.addEventListener("click", () => {
  if (!window.menuIsOpen) {
    window.menuIsOpen = true;
    window.openModal(".menu", false);
    setMenuAttr("img/close.svg", "none");
  } else {
    window.closeModal();
  }
});
