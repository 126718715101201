import "./import/modules";
import "./import/components";
const header = document.querySelector(".page-header");
function closeModal(closeMenu = true) {
  const modal = document.querySelector(".modal.open");
  if (modal) {
    modal.classList.remove("open");
    header.style.background = "";
    header.style.backdropFilter = "";
  }
  if (window.menuIsOpen && closeMenu) {
    window.closeMenu();
  }
}
function openModal(selector, closeMenu = true) {
  closeModal(closeMenu);
  const modal = document.querySelector(".modal" + selector);
  if (modal) {
    modal.classList.add("open");
    header.style.background = "rgba(0, 0, 0, 0.8)";
    header.style.backdropFilter = "blur(10px)";
  }
}

window.closeModal = closeModal;
window.openModal = openModal;
document.querySelector("form").addEventListener("submit", function (e) {
  e.preventDefault();
  let data = new FormData(this);

  fetch("/contact.php", {
    method: "POST",
    body: data,
  })
    .then(() => {
      window.openModal(".success");
    })
    .catch(() => {
      window.openModal(".success");
    });
});
document.querySelectorAll("*[data-tab]").forEach((tab) => {
  const tabPrefix = tab.dataset.tab;
  console.log(tabPrefix);
  tab.addEventListener("change", (e) => {
    console.log("change");
    const id = e.target.value;
    const curr = Array.from(
      document.querySelectorAll(`[data-tabid*=${tabPrefix}]`)
    ).find((el) => el.dataset.tab_checked == "1");
    curr.style.display = "none";
    curr.dataset.tab_checked = "0";
    const target = document.querySelector(`*[data-tabid="${tabPrefix}${id}"]`);
    target.style.display = "";
    target.dataset.tab_checked = "1";
  });
});
document.querySelectorAll("[data-show_button]").forEach((el) => {
  let cont = null;
  let parent = null;
  while (!cont) {
    parent = el.parentNode;
    cont = parent.querySelector("[data-show_cont]");
  }
  const initText = el.textContent;
  let isOpen = false;
  let top = 0;
  el.addEventListener("click", (e) => {
    isOpen = !isOpen;
    if (isOpen) {
      top = window.pageYOffset || window.scrollY;
      el.textContent = "Скрыть";
      Array.from(cont.children).forEach((ch) => (ch.style.display = "flex"));
    } else {
      el.textContent = initText;
      Array.from(cont.children).forEach((ch) => (ch.style.display = ""));
      window.scrollTo({
        left: 0,
        top: top,
      });
    }
  });
});

//yandex карта
if (document.getElementById("ymap")) {
  ymaps.ready(init);
}
if (document.getElementById("ymapbig")) {
  ymaps.ready(initBig);
}

let curr_elem = null;
function hideCard(id) {
  document.getElementById(id).classList.remove("open");
  curr_elem = null;
}
function openCard(id) {
  document.getElementById(id).classList.add("open");
}
window.hideCard = hideCard;
function init() {
  var myMap = new ymaps.Map("ymap", {
    center: [59.939156, 30.311449],
    zoom: 10,
    controls: ["smallMapDefaultSet"],
  });

  myMap.events.add("click", () => {
    if (curr_elem !== null) {
      hideCard(curr_elem);
      curr_elem = null;
    }
  });
  coors.forEach((el) => {
    let place = new ymaps.Placemark(
      el.coors,
      {},
      {
        iconLayout: "default#image",
        iconImageHref: el.photo,
        iconImageSize: [50, 50],
      }
    );
    place.events.add("click", function (e) {
      e.stopPropagation();
      if (curr_elem !== null) {
        hideCard(curr_elem);
      }
      curr_elem = el.id;
      openCard(curr_elem);
    });
    myMap.geoObjects.add(place);
  });
}
function initBig() {
  var myMap1 = new ymaps.Map("ymapbig", {
    center: [59.939156, 30.311449],
    zoom: 10,
    controls: ["smallMapDefaultSet"],
  });

  myMap1.events.add("click", () => {
    if (curr_elem !== null) {
      hideCard(curr_elem);
      curr_elem = null;
    }
  });
  ymapbigcoors.forEach((el) => {
    let place = new ymaps.Placemark(
      el.coors,
      {},
      {
        iconLayout: "default#image",
        iconImageHref: el.photo,
        iconImageSize: [50, 50],
      }
    );
    place.events.add("click", function (e) {
      e.stopPropagation();
      if (curr_elem !== null) {
        hideCard(curr_elem);
      }
      curr_elem = el.id;
      openCard(curr_elem);
    });
    myMap1.geoObjects.add(place);
  });
}
window.openDistrict = () => {
  if (curr_elem !== null) {
    hideCard(curr_elem);
  }
  curr_elem = "ymapbigdist";
  const title = document.querySelector("#district").value;
  document.querySelector(
    ".map__district-title"
  ).textContent = `Вызвать специалиста в
  ${title}`;
  openCard(curr_elem);
};
