import Swiper, { Navigation } from "swiper";

new Swiper("#review-slider", {
  slidesPerView: 1.1,
  spaceBetween: 9,
  modules: [Navigation],
  breakpoints: {
    850: {
      slidesPerView: 2,
      spaceBetween: 30,

      navigation: {
        prevEl: ".reviews-slider__button#prev",
        nextEl: ".reviews-slider__button#next",
        disabledClass: "reviews-slider__button--disabled",
      },
    },
  },
});
if (window.innerWidth <= 850) {
  const slides = document.querySelectorAll(".reviews-slider__slide");
  const SLIDE_HEIGHT = "206px";
  slides.forEach((slide) => {
    const slideText = slide.querySelector(".reviews-slider__slide-text");
    const slideShow = slide.querySelector(".reviews-slider__slide-show");
    if (slideText.clientHeight > 206) {
      slideText.style.height = SLIDE_HEIGHT;
      slideShow.style.display = "block";
      let slideOpen = false;
      slideShow.addEventListener("click", () => {
        slideOpen = !slideOpen;
        if (slideOpen) {
          slideText.style.height = slideText.scrollHeight + "px";
          slideShow.textContent = "Скрыть отзыв";
        } else {
          slideText.style.height = SLIDE_HEIGHT;
          slideShow.textContent = "Читать отзыв";
        }
      });
    }
  });
}
