import Swiper, { Navigation } from "swiper";

new Swiper(".about-slider__slider-wrapper", {
  slidesPerView: 1.1,
  spaceBetween: 9,
  modules: [Navigation],
  breakpoints: {
    850: {
      slidesPerView: 2,
      spaceBetween: 30,

      navigation: {
        prevEl: "#about-prev",
        nextEl: "#about-next",
        disabledClass: "reviews-slider__button--disabled",
      },
    },
  },
});
