import Swiper from "swiper";

new Swiper(".portfolio-slider", {
  slidesPerView: 1.1,
  spaceBetween: 9,

  breakpoints: {
    850: {
      slidesPerView: 2,
      spaceBetween: 30,
      initialSlide: 1,
      loop: true,
    },
  },
});
