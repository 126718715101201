const cont = document.querySelector("#masters");

if (cont) {
  const masters = cont.querySelectorAll(".masters-item");
  masters.forEach((master) => {
    master.addEventListener("click", () => {
      const openedMaster = cont.querySelector(".masters-item--open");
      openedMaster.classList.remove("masters-item--open");
      master.classList.add("masters-item--open");
    });
  });
}
